/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { API, get } from '../../../config.js';
import SupportTicketActionButton from './SupportTicketActionButton.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFolderPlus,
  faClock,
  faExclamationCircle,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons';
import { checkRole } from '../../../helper/utils.js';

// const roleName = localStorage.getItem('role_name');
const roleType = localStorage.getItem('role_type');

export const getStatusIcon = (status, is_resolved) => {
  switch (status) {
    case '1':
      return is_resolved ? (
        <div className="support-status-resolved support-status-body">
          {' '}
          <FontAwesomeIcon icon={faCheckCircle} title="Resolved" />
          <span className="support-status-text">Resolved</span>
        </div>
      ) : (
        <div className="support-status-new support-status-body">
          {' '}
          <FontAwesomeIcon icon={faFolderPlus} title="New" />
          <span className="support-status-text">New</span>
        </div>
      );
    case '2':
      return is_resolved ? (
        <div className="support-status-resolved support-status-body">
          {' '}
          <FontAwesomeIcon icon={faCheckCircle} title="Resolved" />
          <span className="support-status-text">Resolved</span>
        </div>
      ) : (
        <div className="support-status-open support-status-body">
          {' '}
          <FontAwesomeIcon icon={faExclamationCircle} title="Open" />
          <span className="support-status-text">Open</span>
        </div>
      );
    case '3':
      return is_resolved ? (
        <div className="support-status-resolved support-status-body">
          {' '}
          <FontAwesomeIcon icon={faCheckCircle} title="Resolved" />
          <span className="support-status-text">Resolved</span>
        </div>
      ) : (
        <div className="support-status-in-progress support-status-body">
          {' '}
          <FontAwesomeIcon icon={faClock} title="Pending" />
          <span className="support-status-text">Pending</span>
        </div>
      );
    case '4':
      return (
        <div className="support-status-closed support-status-body">
          {' '}
          <FontAwesomeIcon icon={faXmarkCircle} title="Closed" />
          <span className="support-status-text">Closed</span>
        </div>
      );
    default:
      return null;
  }
};
export const getTableColumns = (fetchSupportTicketsList) => {
  return checkRole(roleType)
    ? [
        {
          title: 'No.',
          dataIndex: 'id',
          render: (id) => (id ? id : ''),
          sorter: (a, b) => a.id.localeCompare(b.id)
        },
        {
          title: 'Firm',
          dataIndex: 'accounting_firm',
          className: 'fullname',
          render: (accounting_firm) =>
            accounting_firm?.business_name
              ? accounting_firm?.business_name?.charAt(0) + accounting_firm?.business_name
              : ''
        },
        {
          title: 'Subject',
          dataIndex: 'subject',
          render: (subject) => (subject ? subject : '-')
        },
        {
          title: 'Created By',
          // dataIndex: 'access_from_date',
          render: (record) =>
            // access_from_date ? access_from_date.split('-').reverse().join('-') : ''
            record?.details?.replied_by?.first_name
              ? record?.details?.replied_by?.first_name +
                ' ' +
                record?.details?.replied_by?.last_name
              : record?.details?.replied_by?.last_name
        },
        // {
        //   title: 'Access From Date',
        //   dataIndex: 'access_from_date',
        //   sorter: (a, b) => a.access_from_date.localeCompare(b.access_from_date),
        //   render: (access_from_date) =>
        //     access_from_date ? access_from_date.split('-').reverse().join('-') : ''
        // },

        // {
        //   title: 'Access To Date',
        //   dataIndex: 'access_to_date',
        //   sorter: (a, b) => a.access_to_date.localeCompare(b.access_to_date),
        //   render: (access_to_date) =>
        //     access_to_date ? access_to_date.split('-').reverse().join('-') : ''
        // },
        {
          title: 'Access Given By',
          dataIndex: 'access_given_by',
          render: (data) =>
            data?.access_given_date
              ? data?.access_given_by?.first_name
                ? data?.access_given_by?.first_name + ' ' + data?.access_given_by?.last_name
                : data?.access_given_by?.last_name
              : '-'
        },
        {
          title: 'Access Given Date',
          dataIndex: 'access_given_date',
          sorter: (a, b) => a.access_given_date.localeCompare(b.access_given_date),
          render: (access_given_date) =>
            access_given_date ? access_given_date.split('-').reverse().join('-') : '-'
        },
        {
          title: 'Access Revoke Date',
          dataIndex: 'access_revoke_date',
          sorter: (a, b) => a.access_revoke_date.localeCompare(b.access_revoke_date),
          render: (access_revoke_date) =>
            access_revoke_date ? access_revoke_date.split('-').reverse().join('-') : '-'
        },
        {
          title: 'Status',
          className: 'centeralign',
          render: (data) => (data?.status ? getStatusIcon(data?.status, data?.is_resolved) : '')
        },
        {
          title: 'Action',
          dataIndex: 'id',
          width: '10%',
          render: (id, data) => (
            <SupportTicketActionButton
              supportDetails={data}
              data={{ id: id }}
              id={id}
              fetchSupportTickets={fetchSupportTicketsList}
            />
          )
        }
      ]
    : [
        {
          title: 'No.',
          dataIndex: 'id',
          render: (id) => (id ? id : ''),
          sorter: (a, b) => a.id.localeCompare(b.id)
        },
        {
          title: 'Subject',
          dataIndex: 'subject',
          render: (subject) => (subject ? subject : '-')
        },
        {
          title: 'Created By',
          // dataIndex: 'access_from_date',
          render: (record) =>
            // access_from_date ? access_from_date.split('-').reverse().join('-') : ''
            record?.details?.replied_by?.first_name
              ? record?.details?.replied_by?.first_name +
                ' ' +
                record?.details?.replied_by?.last_name
              : record?.details?.replied_by?.last_name
        },
        // {
        //   title: 'Access From Date',
        //   dataIndex: 'access_from_date',
        //   sorter: (a, b) => a.access_from_date.localeCompare(b.access_from_date),
        //   render: (access_from_date) =>
        //     access_from_date ? access_from_date.split('-').reverse().join('-') : ''
        // },

        // {
        //   title: 'Access To Date',
        //   dataIndex: 'access_to_date',
        //   sorter: (a, b) => a.access_to_date.localeCompare(b.access_to_date),
        //   render: (access_to_date) =>
        //     access_to_date ? access_to_date.split('-').reverse().join('-') : ''
        // },
        {
          title: 'Access Given By',
          dataIndex: 'access_given_by',
          render: (data) =>
            data?.access_given_date
              ? data?.access_given_by?.first_name
                ? data?.access_given_by?.first_name + ' ' + data?.access_given_by?.last_name
                : data?.access_given_by?.last_name
              : '-'
        },
        {
          title: 'Access Given Date',
          dataIndex: 'access_given_date',
          sorter: (a, b) => a.access_given_date.localeCompare(b.access_given_date),
          render: (access_given_date) =>
            access_given_date ? access_given_date.split('-').reverse().join('-') : '-'
        },
        {
          title: 'Access Revoke Date',
          dataIndex: 'access_revoke_date',
          sorter: (a, b) => a.access_revoke_date.localeCompare(b.access_revoke_date),
          render: (access_revoke_date) =>
            access_revoke_date ? access_revoke_date.split('-').reverse().join('-') : '-'
        },
        {
          title: 'Status',
          className: 'centeralign',
          render: (data) => (data?.status ? getStatusIcon(data?.status, data?.is_resolved) : '')
        },
        {
          title: 'Action',
          dataIndex: 'id',
          width: '10%',
          render: (id, data) => (
            <SupportTicketActionButton
              supportDetails={data}
              data={{ id: id }}
              id={id}
              fetchSupportTickets={fetchSupportTicketsList}
            />
          )
        }
      ];
};

export const getSupportDetail = async (id) => {
  try {
    const { data } = await get(
      checkRole(roleType) ? `${API.ADMIN_SUPPORT_TICKET}/${id}` : `${API.SUPPORT_TICKET}/${id}`
    );
    return data.data;
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    return null;
  }
};

export const useOfficeList = () => {
  const [officeList, setOfficeList] = useState([]);
  const [officeDataLoading, setOfficeDataLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setOfficeDataLoading(true);
      const { data } = await get(`${API.GET_OFFICE_LIST}?limit=-1`);
      setOfficeDataLoading(false);
      setOfficeList(data.data.data);
    } catch (e) {
      setOfficeList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    officeList,
    officeDataLoading
  };
};

export const useRoleList = () => {
  const [roleList, setRoleList] = useState([]);
  const [roleDataLoading, setRoleDataLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRoleDataLoading(true);
      const { data } = await get(`${API.GET_ROLE_LIST}?role_type=${roleType}`);
      setRoleDataLoading(false);
      setRoleList(data.data.data);
    } catch (e) {
      setRoleList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    roleList,
    roleDataLoading
  };
};

export const phoneValidationRules = [
  {
    required: true,
    message: 'Please enter Phone number'
  },
  {
    pattern: new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/),
    message: 'Please enter only number'
  },
  {
    min: 9,
    message: 'Minimum 9 digits are required in Phone number'
  },
  {
    max: 16,
    message: 'Maximum 16 digits are allowed in Phone number'
  }
];
