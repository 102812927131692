import React from 'react';
import InputMask from 'react-input-mask';
import Form from '@component/Form';
import { Button, Input, Select, Checkbox, Spin, InputNumber } from 'antd';

// import { toInteger } from 'lodash';
import SidebarNav from '../../components/Header/SidebarNav';
import BackButton from '../../components/Form/BackButton';
import Footer from '../../components/Footer/Index';
import CancelButton from '../../components/Form/CancelButton';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  getImportLogDetail,
  useStateList,
  useSuburbList,
  useSuburbList1
} from './ImportLogsHelper';
import { FullNameGetterWithoutChartAt, maskPostalCode } from '../../helper/fullNameGetter';
import { toast } from 'react-toastify';
import { API, fileUpload, get } from '../../config';
import { identity, pickBy } from 'lodash';

// const { TextArea } = Input;

const NotImportedLogEdit = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const importLogId = query.get('id');
  const [loading, setLoading] = useState(false);
  const [importLogDetail, setImportLogDetail] = useState(null);
  const [TFNRules, setTFNRules] = useState([
    {
      required: true,
      message: 'Please enter TFN'
    }
  ]);

  const ClientType = [
    { id: '1', name: 'Individual' },
    { id: '2', name: 'Non Individual' }
  ];

  const ClientStatus = [
    { id: '1', name: 'Prospect' },
    { id: '2', name: 'Current' },
    { id: '3', name: 'Non Current' }
  ];

  const { stateLoading, stateList = [] } = useStateList();
  const [stateId, setStateId] = useState();
  const [stateId1, setStateId1] = useState();
  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
    setSuburbList,
    selectedState
  } = useSuburbList(stateId);
  const {
    suburbLoading1,
    suburbList1 = [],
    handleStateSelect1,
    setSuburbList1,
    selectedState1
  } = useSuburbList1(stateId1);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const importLogRes = await getImportLogDetail(importLogId);
      setImportLogDetail(importLogRes);
      setStateId(importLogRes?.billing_state?.id ? importLogRes?.billing_state?.id : '');
      setStateId1(importLogRes?.shipping_state?.id ? importLogRes?.shipping_state?.id : '');
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function suburb(stateId) {
      try {
        const { data } = await get(`${API.GET_SUBURB_LIST}/${stateId}`);
        setSuburbList(data.data);
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong.');
        }
      }
    }
    if (stateId) suburb(stateId);
  }, [stateId]);

  useEffect(() => {
    async function suburb1(stateId1) {
      try {
        const { data } = await get(`${API.GET_SUBURB_LIST}/${stateId1}`);
        setSuburbList1(data.data);
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong.');
        }
      }
    }
    if (stateId1) suburb1(stateId1);
  }, [stateId1]);

  useEffect(() => {
    if (suburbList.length && selectedState) {
      form.setFieldsValue({ suburb_id: suburbList[0].id });
    }
    if (suburbList1.length && selectedState1) {
      form.setFieldsValue({ shipping_suburb_id: suburbList1[0].id });
    }
  }, [form, selectedState, suburbList, selectedState1, suburbList1]);

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const reqObj = pickBy(values, identity);

      const formData = new FormData();

      Object.keys(reqObj).forEach((key) => {
        formData.append([key], reqObj[key]);
      });

      formData.append('_method', 'put');
      const { data } = await fileUpload(`${API.IMPORT_LOGS}/${importLogId}`, formData);
      setLoading(false);
      toast.success(data?.message);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  function onSearch() {}
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
      </div>
      <div className="full-content-block">
        {importLogDetail !== undefined && importLogDetail !== null && loading === false ? (
          <>
            <h1 className="page-title">
              <BackButton />
              {FullNameGetterWithoutChartAt(importLogDetail)}
            </h1>
            <div className="content-details">
              <div className="entry-section">
                <Form
                  form={form}
                  initialValues={{
                    // ...importLogDetail,
                    first_name: importLogDetail?.first_name ? importLogDetail?.first_name : '',
                    last_name: importLogDetail?.last_name ? importLogDetail?.last_name : '',
                    tfn: importLogDetail?.tfn ? importLogDetail?.tfn : '',
                    email: importLogDetail?.email ? importLogDetail?.email : '',
                    phone: importLogDetail?.phone ? importLogDetail?.phone : '',
                    mobile: importLogDetail?.mobile ? importLogDetail?.mobile : '',
                    business_name: importLogDetail?.bussiness_name
                      ? importLogDetail?.bussiness_name
                      : '',
                    abn: importLogDetail?.abn ? importLogDetail?.abn : '',
                    customer_type: importLogDetail?.customer_type
                      ? importLogDetail?.customer_type
                      : '',
                    client_status: importLogDetail?.client_status
                      ? importLogDetail?.client_status
                      : '',
                    opening_balance: 0,
                    crn: importLogDetail?.crn ? importLogDetail?.crn : '',

                    billing_street: importLogDetail?.billing_street
                      ? importLogDetail?.billing_street
                      : '',
                    billing_state: importLogDetail?.billing_state?.id
                      ? importLogDetail?.billing_state?.id
                      : '',
                    billing_suburb: importLogDetail?.billing_suburb?.id
                      ? importLogDetail?.billing_suburb?.id
                      : '',
                    billing_postal_code: importLogDetail?.billing_postal_code
                      ? maskPostalCode(Number(importLogDetail?.billing_postal_code)) 
                      : '',

                    shipping_street: importLogDetail?.shipping_street
                      ? importLogDetail?.shipping_street
                      : '',
                    shipping_state: importLogDetail?.shipping_state?.id
                      ? importLogDetail?.shipping_state?.id
                      : '',
                    shipping_suburb: importLogDetail?.shipping_suburb?.id
                      ? importLogDetail?.shipping_suburb?.id
                      : '',
                    shipping_postal_code: importLogDetail?.shipping_postal_code
                      ? maskPostalCode(Number(importLogDetail?.shipping_postal_code))
                      : '',

                    account_name: importLogDetail?.account_name
                      ? importLogDetail?.account_name
                      : '',
                    bsb: importLogDetail?.bsb ? importLogDetail?.bsb : '',
                    account_number: importLogDetail?.account_number
                      ? importLogDetail?.account_number
                      : ''
                  }}
                  name="importLogForm"
                  onFinish={handleFinish}
                  layout="vertical"
                >
                  <div className="entry-card">
                    <h3>Personal Details</h3>
                    <div className="form-box">
                      <Form.Item
                        className="half-width"
                        label="First Name"
                        name="first_name"
                        rules={[
                          {
                            max: 32,
                            message: 'Must be less than 32 character'
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z\s]*$/),
                            message: 'First name can contain letters & spaces.'
                          }
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Last Name Or Trading Name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Last name'
                          },
                          {
                            max: 150,
                            message: 'Must be less than 150 character'
                          },
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&]*$/),
                            message:
                              'Last name can contain letters, numbers, ampersand(&), dot(.), comma(,) & spaces.'
                          }
                        ]}
                      >
                        <Input /*onChange={handleLastName}*/ placeholder="Last Name" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="TFN"
                        name="tfn"
                        onChange={() => {
                          setTFNRules([
                            {
                              required: true,
                              message: 'Please enter TFN'
                            },
                            {
                              pattern: new RegExp(/^([0-9]+$)([ .-]?)/),
                              message: 'please enter valid TFN'
                            },
                            {
                              min: 8,
                              message: 'Minimum 8 digits are required in TFN'
                            },
                            {
                              max: 9,
                              message: 'Maximum 9 digits are allowed in TFN'
                            },
                            {
                              validator: (_, value) => {
                                if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                                  return Promise.reject(
                                    'Please enter valid TFN Number. All digits of same number are not allowed.'
                                  );
                                } else {
                                  if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                                    return Promise.reject(
                                      'Please enter valid TFN Number. All digits of same number are not allowed.'
                                    );
                                  }
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]);
                        }}
                        rules={TFNRules}
                      >
                        <Input placeholder="123412345" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Email"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'Please enter valid Email'
                          },
                          {
                            required: true,
                            message: 'Please enter Email'
                          }
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item className="half-width" label="Phone Number" name="phone">
                        <InputMask
                          mask="99 9999 9999"
                          name="phone"
                          placeholder="12 1234 1234"
                          className="ant-input"
                        />
                      </Form.Item>
                      <Form.Item className="half-width" label="Mobile Number" name="mobile">
                        <InputMask
                          mask="9999 999 999"
                          name="mobile"
                          placeholder="1234 123 123"
                          className="ant-input"
                        />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Business Name"
                        name="business_name"
                        rules={[
                          {
                            max: 150,
                            message: 'Must be less than 150 character'
                          },
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/),
                            message: `Business name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.`
                          }
                        ]}
                      >
                        <Input /*onChange={handleBussinessName}*/ placeholder="Business Name" />
                      </Form.Item>

                      <Form.Item
                        className="half-width"
                        label="ABN"
                        name="abn"
                        rules={[
                          {
                            len: 11,
                            message: 'Must be 11 digit number'
                          },
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: 'Value should be number'
                          }
                        ]}
                      >
                        <Input placeholder="12345678911" />
                      </Form.Item>
                      <Form.Item className="half-width" label="Client Type" name="customer_type">
                        <Select
                          showSearch={false}
                          optionFilterProp="children"
                          onSearch={onSearch}
                          placeholder="Please select Client Type"
                        >
                          {ClientType.map((type) => (
                            <Select.Option key={type.id} value={type.id}>
                              {type.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item className="half-width" label="Type" name="client_status">
                        <Select
                          showSearch={false}
                          optionFilterProp="children"
                          onSearch={onSearch}
                          placeholder="Please select Type"
                        >
                          {ClientStatus.map((type) => (
                            <Select.Option key={type.id} value={type.id}>
                              {type.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Opening Balance"
                        name="opening_balance"
                      >
                        <InputNumber placeholder="0.00" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="CRN"
                        name="crn"
                        rules={[
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                            message: 'Value should be Alphanumberic'
                          }
                        ]}
                      >
                        <Input placeholder="Client Reference Number" />
                      </Form.Item>
                      <div className="address-box">
                        <h5>Postal Address</h5>
                        <Form.Item
                          className="half-width"
                          label="Address"
                          name="billing_street"
                          rules={[
                            {
                              pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                              message: `Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                            }
                          ]}
                        >
                          <Input placeholder="Street" />
                        </Form.Item>
                        <Form.Item className="half-width" label="Select State" name="billing_state">
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            onChange={handleStateSelect}
                            placeholder="Please select State"
                            loading={stateLoading}
                          >
                            {stateList.map((item, i) => (
                              <Select.Option key={i} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Select Suburb"
                          name="billing_suburb"
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            loading={suburbLoading}
                            placeholder="Please select Suburb"
                          >
                            {suburbList.length &&
                              suburbList.map((item, i) => (
                                <Select.Option key={i} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Post Code"
                          name="billing_postal_code"
                          rules={[
                            {
                              pattern: new RegExp(/^\d{4}$/),
                              message: 'Must be 4 digit number'
                            }
                          ]}
                        >
                          <Input placeholder="Post Code" type="number" />
                        </Form.Item>
                      </div>
                      <div className="address-box">
                        <h5>Street Address</h5>
                        <Form.Item
                          className="half-width"
                          label="Address"
                          name="shipping_street"
                          rules={[
                            {
                              pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                              message: `Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                            }
                          ]}
                        >
                          <Input placeholder="Street" />
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Select State"
                          name="shipping_state"
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            onChange={handleStateSelect1}
                            loading={stateLoading}
                            placeholder="Please select State"
                          >
                            {stateList.map((item, i) => (
                              <Select.Option key={i} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Select Suburb"
                          name="shipping_suburb"
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            loading={suburbLoading1}
                            placeholder="Please select Suburb"
                          >
                            {suburbList1.length &&
                              suburbList1.map((item, i) => (
                                <Select.Option key={i} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Post Code"
                          name="shipping_postal_code"
                          rules={[
                            {
                              pattern: new RegExp(/^\d{4}$/),
                              message: 'Must be 4 digit number'
                            }
                          ]}
                        >
                          <Input placeholder="Post Code" type="number" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="entry-card">
                    <h3>Bank Details</h3>
                    <div className="form-box">
                      <Form.Item
                        className="half-width"
                        label="Account Name"
                        name="account_name"
                        rules={[
                          {
                            max: 32,
                            message: 'Must be less than 32 character'
                          },
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/),
                            message: `Account name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.`
                          }
                        ]}
                      >
                        <Input placeholder="Account Name" />
                      </Form.Item>
                      <div className="half-width"></div>
                      <Form.Item className="half-width" label="BSB" name="bsb">
                        <InputMask
                          mask="999-999"
                          name="bsb"
                          placeholder="123-456"
                          className="ant-input"
                        />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Account Number"
                        name="account_number"
                        validateTrigger={['onBlur']}
                        rules={[
                          {
                            pattern: new RegExp(/^\d{0,9}$/),
                            message: 'Must be less than 10 digit number'
                          },
                          {
                            validator: (_, value) => {
                              if (
                                (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                                (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                                (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                                (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                                (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                                (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                                (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                                (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                              ) {
                                return Promise.reject(
                                  'Please enter valid Account Number. All digits of same number are not allowed.'
                                );
                              } else {
                                return Promise.resolve();
                              }
                            }
                          }
                        ]}
                      >
                        <Input placeholder="xxxxxxxx1234" />
                      </Form.Item>
                      <Form.Item
                        className="full-width"
                        name="fee_from_refund"
                        valuePropName="checked"
                        wrapperCol={{
                          span: 16
                        }}
                      >
                        <Checkbox>Pre-Payment of Refund</Checkbox>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="action-box">
                    <Button
                      type="primary"
                      //   loading={loading}
                      //   disabled={loading}
                      className="saveBtn"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                    <CancelButton />
                  </div>
                </Form>
              </div>
            </div>
          </>
        ) : (
          <Spin />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default NotImportedLogEdit;
