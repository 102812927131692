import React, { useState } from 'react';
import { Button, Modal, Select, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, Input } from 'antd';
import SidebarNav from '../../../components/Header/SidebarNav';
import Footer from '../../../components/Footer/Index';
import BackButton from '../../../components/Form/BackButton';
import useSupportList from './useSupportList';
import { getTableColumns } from './SupportTicketHelper';
import '../../../App.css';
import '../../../assets/css/style.css';
import './users.module.css';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import DialogModal from '../../../components/Header/DialogModal';
import { helpCenterArray } from '../../HelpCenter';
import { API, defaultPaginationLimit, fileUpload } from '../../../config';
import { toast } from 'react-toastify';

import { Modal as BSModal } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { checkRole } from '../../../helper/utils';
import { SearchOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as BSButton } from 'react-bootstrap';

const SupportTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const roleName = localStorage.getItem('role_name');
  const roleType = localStorage.getItem('role_type');

  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const currentPage = query.get('page');
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [showSupportUser, setShowSupportUser] = useState(false);

  const [subject, setSubject] = useState();
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [editorState, setEditorState] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
  };
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    supportTicket,
    fetchSupportTickets,
    onChange,
    searchParam,
    isDataLoading,
    pagingData,
    onPageChange
  } = useSupportList(currentPage, search);

  const fetchSupportTicketsList = () => {
    fetchSupportTickets();
  };

  const handleChange = async (value) => {
    setLimit(value);
    if (checkRole(roleType)) {
      navigate(`/admin/support-tickets?page=1&limit=${value}`, {
        replace: true
      });
    } else {
      navigate(`/support-tickets?page=1&limit=${value}`, {
        replace: true
      });
    }

    fetchSupportTickets(1, value);
  };

  const toggleSupportModal = () => {
    setShowSupportModal(!showSupportModal);
  };
  const toggleSupportUserModal = () => {
    setShowSupportUser(!showSupportUser);
  };

  const handleFileChange = (info) => {
    setFiles(info.fileList);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('subject', subject);
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    formData.append('message', value);
    files.forEach((file, index) => {
      if (file !== undefined) {
        formData.append(`attachments[${index}]`, file.originFileObj);
      }
    });
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    fileUpload(
      checkRole(roleType) ? API.ADMIN_SUPPORT_TICKET : API.SUPPORT_TICKET,
      formData,
      config
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data.message);
          fetchSupportTickets();
          toggleSupportModal();
          toggleSupportUserModal();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        const { data } = err.response;
        toast.error(data?.message);
        toast.error(data.errors && data.errors.customer_file && data.errors.customer_file[0]);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };

  const { Dragger } = Upload;

  const fileProps = {
    name: 'file',
    multiple: true,
    headers: {
      authorization: 'authorization-text'
    },
    accept: '*',
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      handleFileChange(info);
    }
  };

  const checkDisable = () => {
    if (subject && editorState?.getCurrentContent()?.hasText()) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        {!checkRole(roleType) && <SidebarNav />}
        <div className="full-content-block admin-list-table">
          <h1 className="page-title">
            <BackButton />
            Support Tickets{' '}
            <DialogModal
              title="Dashboard"
              p1={helpCenterArray[36].name}
              p2={helpCenterArray[36].text}
            />
          </h1>
          {/* <h2>Support Tickets</h2> */}
          <div className="content-details">
            <div className="table-top-btn">
              <div className="">
                <Input
                  style={{ width: '200%' }}
                  onChange={(e) => onChange(e)}
                  suffix={<SearchOutlined />}
                  value={searchParam}
                  placeholder="Search Tickets"
                  allowClear
                />
              </div>
              <div className="table-btn">
                {!checkRole(roleType) && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      toggleSupportUserModal();
                    }}
                  >
                    Add Support Ticket
                  </Button>
                )}
              </div>
            </div>
            <div style={{ width: '100%', height: '85%' }}>
              <Table
                rowKey={(record) => record.id}
                columns={getTableColumns(fetchSupportTicketsList)}
                dataSource={supportTicket}
                loading={isDataLoading}
                pagination={{
                  hideOnSinglePage: true,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page, limit),
                  itemRender: PaginationItemRenderer,
                  ...pagingData,
                  pageSize: limit
                }}
              />
              {supportTicket?.length > 0 && (
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {showSupportUser && (
        <BSModal
          show={showSupportUser}
          onHide={toggleSupportUserModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <BSModal.Header closeButton>Add Support Ticket</BSModal.Header>
          <BSModal.Body>
            <div className="support-ticket support-ticket-framebox">
              <div className="support-ticket-field support">
                <label className="label-required">Subject</label>
                <input
                  type="text"
                  name={`subject`}
                  value={subject ? subject : ''}
                  onChange={(e) => {
                    setSubject(e?.target?.value);
                  }}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                />
              </div>
              <div className="support-ticket-field">
                <label className="label-required">Message</label>
                <Editor
                  editorState={editorState}
                  toolbar={{
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    },
                    options: [
                      'inline',
                      // 'blockType',
                      'fontSize',
                      'list',
                      'textAlign',
                      'remove',
                      'history'
                    ]
                  }}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
              </div>
              <div className="support-ticket-field">
                <label>Attachment</label>
                <div className="upload-div">
                  <Dragger {...fileProps}>
                    <p className="ant-upload-drag-icon">
                      <FontAwesomeIcon icon={faUpload} size="3x" />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </Dragger>
                  <div className="upload-btn-div">
                    <BSButton
                      disabled={isLoading || checkDisable()}
                      variant="primary"
                      onClick={() => toggleSupportModal()}
                      className="upload"
                    >
                      {isLoading ? 'Loading...' : 'Upload'}
                    </BSButton>
                    <BSButton
                      variant="secondary"
                      onClick={() => toggleSupportUserModal()}
                      className="cancel-btn"
                    >
                      Cancel
                    </BSButton>
                  </div>
                </div>
              </div>
            </div>
          </BSModal.Body>
        </BSModal>
      )}
      {showSupportModal && (
        <Modal
          className="remove-popup"
          width={500}
          closable
          centered
          open
          footer={null}
          onCancel={toggleSupportModal}
        >
          <div className="remove-content spin-body-relative">
            <p>Are your sure you want to add support ticket?</p>
            <div className="modal-action">
              <Button className="cancel" onClick={toggleSupportModal}>
                Cancel
              </Button>
              <Button onClick={handleUpload}>OK</Button>
            </div>
          </div>
          {isLoading && (
            <div className="modal-spin-center">
              <Spin />
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default SupportTicket;
