/* eslint-disable no-nested-ternary */
import React from 'react';

// import { toast } from 'react-toastify';
import { Input, Button, Select } from 'antd';
import InputMask from 'react-input-mask';
import Form from '@component/Form';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { API, fileUpload, get } from '../../config';
import NumberFormat from 'react-number-format';
import { useLocation } from 'react-router-dom';
const AddEditBeneficiary = ({
  onClose,
  clientId,
  clientData,
  setBlockData,
  blockData,
  getWithdrawalAccountBlockData,
  // getCustomerBlockDetail,
  defaultAccount,
  itemPaymentIndex,
  itemJson
}) => {
  const [form] = Form.useForm();
  const [bankOptions, setBankOptions] = useState([{ label: 'Please Select Bank', value: '' }]);
  const [bankSearch, setBankSearch] = useState('');
  const { state } = useLocation();
  const handleBankSearch = (value) => {
    setBankSearch(value);
  };

  const getBankList = async (searchValue) => {
    try {
      const { data } = await get(`${API.BANK_ABBREVIATION}?search=${searchValue}`);
      setBankOptions([{ label: 'Please Select Bank', value: '' }, ...data.data]);
    } catch (error) {
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(async () => {
    getBankList(bankSearch);
  }, [bankSearch]);

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      const url = API.BENEFICIARY;
      const reqObj = { ...values, customer_id: clientId ? clientId : '' };
      Object.keys(reqObj).forEach((key) => {
        if (reqObj[key]) {
          formData.append([key], reqObj[key]);
        }
      });
      const { data } = await fileUpload(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success(data?.message);
      let index = await blockData?.findIndex((item) => item.id === clientId);
      let newArr = blockData;
      newArr[index].clientData = {
        ...clientData,
        beneficiaries: [...clientData.beneficiaries, data?.data]
      };
      let withdrawalAccountData = await getWithdrawalAccountBlockData();
      // let customerDataArray = await getCustomerBlockDetail(clientId, index, newArr);
      let block = newArr;
      let i = itemPaymentIndex;
      // for (let i = 0; i < block?.length; i++) {
      let lastElement = block[i]?.payments[block[i]?.payments.length - 1];
      let withdrawalData =
        lastElement?.withdrawal_account_id && withdrawalAccountData
          ? [
              {
                ...withdrawalAccountData,

                chart_account_id:
                  block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                    ? block[i]?.payments[0]?.chart_account_id
                    : defaultAccount?.id,

                amount:
                  block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                    ? block[i]?.payments[0]?.amount
                    : lastElement?.amount,

                bsb:
                  block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                    ? block[i]?.payments[0]?.bsb
                    : withdrawalAccountData?.bsb,

                account_number:
                  block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                    ? block[i]?.payments[0]?.account_number
                    : withdrawalAccountData?.account_number,

                description:
                  block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                    ? block[i]?.payments[0]?.description ?? 'Professional Fees'
                    : 'Professional Fees',

                type: 'default',

                withdrawal_account_id: withdrawalAccountData?.id
              }
            ]
          : [];

      let clientObject = {};

      clientObject = block[i]?.payments?.find(
        (item) =>
          item?.type === 'default' &&
          !item?.withdrawal_account_id &&
          item?.client_id === block[i]?.id
      );

      let clientAccountData = [];

      if (block[i]?.clientData) {
        clientAccountData.push({
          chart_account_id: clientObject?.chart_account_id
            ? clientObject?.chart_account_id
            : defaultAccount?.id,

          first_name: block[i]?.clientData?.first_name ? block[i]?.clientData?.first_name : '',

          last_name: block[i]?.clientData?.last_name ? block[i]?.clientData?.last_name : '',

          account_name: block[i]?.clientData?.customer_bank?.account_name,

          account_number: clientObject?.account_number
            ? clientObject?.account_number
            : block[i]?.clientData?.customer_bank?.account_number,

          bsb: clientObject?.bsb ? clientObject?.bsb : block[i]?.clientData?.customer_bank?.bsb,

          client_id: block[i]?.clientData?.id,

          beneficiary_id: '',

          description: itemJson?.description
            ? itemJson?.description
            : state?.description
            ? state.description
            : clientObject?.description,

          amount: itemJson?.amount
            ? NumberFormat(itemJson?.amount)
            : state?.amount
            ? NumberFormat(state.amount)
            : clientObject?.amount,

          clientData: block[i]?.clientData,

          type: 'default'
        });
      }

      const beneficiaryAccountData = [];
      if (block[i]?.clientData?.beneficiaries && block[i]?.clientData?.beneficiaries?.length > 0) {
        block[i]?.clientData?.beneficiaries.map((obj, index) => {
          let beneficiaryObject = block[i]?.payments
            ? block[i]?.payments?.find(
                (item) => item?.beneficiary_id && item?.beneficiary_id === obj?.id
              )
            : {};

          if (index != -1) {
            beneficiaryAccountData.push({
              ...obj,

              beneficiary_id: beneficiaryObject?.beneficiary_id
                ? beneficiaryObject?.beneficiary_id
                : obj?.id,

              chart_account_id: beneficiaryObject?.chart_account_id
                ? beneficiaryObject?.chart_account_id
                : defaultAccount?.id,

              amount: beneficiaryObject?.amount ? beneficiaryObject?.amount : '',

              description: beneficiaryObject?.description ? beneficiaryObject?.description : '',

              bsb: beneficiaryObject?.bsb ? beneficiaryObject?.bsb : obj?.bsb,

              account_number: beneficiaryObject?.account_number
                ? beneficiaryObject?.account_number
                : obj?.account_number
            });
          } else {
            beneficiaryAccountData.push(obj);
          }
        });
      }

      if (block[i]?.id) {
        if (block[i]?.type == 2) {
          block[i].payments = [...clientAccountData];
        } else {
          block[i].payments = [...clientAccountData, ...beneficiaryAccountData, ...withdrawalData];
        }
      } else {
        block[i].payments = [];
      }
      // }
      setBlockData(block);
      onClose();
    } catch (e) {
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <div className="content-details">
      <Form
        form={form}
        initialValues={{
          account_name: '',
          bsb: '',
          account_number: '',
          bank_name: ''
        }}
        name="beneficiariesForm"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="input-boxes">
          <Form.Item
            className="half-width"
            label="Beneficiary Name"
            name="account_name"
            rules={[
              {
                required: true,
                message: 'Please Enter Beneficiary Name'
              },
              {
                max: 200,
                message: 'Must be less than 200 character'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/),
                message: `Beneficiary name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.`
              }
            ]}
          >
            <Input placeholder="Beneficiary Name" name="account_name" />
          </Form.Item>

          <Form.Item
            className="half-width"
            label="BSB"
            name="bsb"
            rules={[
              {
                required: true,
                message: 'Please Enter BSB Number'
              }
            ]}
          >
            <InputMask mask="999-999" name="bsb" placeholder="123-456" className="ant-input" />
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Account Number"
            name="account_number"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please Enter Account Number'
              },
              {
                pattern: new RegExp(/^\d{0,9}$/),
                message: 'Must be less than 10 digit number'
              },
              {
                validator: (_, value) => {
                  if (
                    (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                    (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                    (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                    (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                    (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                    (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                    (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                    (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                  ) {
                    return Promise.reject(
                      'Please enter valid Account Number. All digits of same number are not allowed.'
                    );
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            <Input placeholder="xxxxxxxx1234" />
          </Form.Item>
          <Form.Item
            label="Bank Name"
            name="bank_name"
            // validateTrigger={['onBlur']}
            className="half-width"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please select Bank Name'
            //   }
            // ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={handleBankSearch}
              placeholder={'Please select Bank'}
              allowClear
            >
              {bankOptions.map((item, i) => (
                <Select.Option
                  key={i}
                  value={item.abbreviation ? item.abbreviation : ''}
                >
                  {item.abbreviation ? `${item.abbreviation + " - " + item.bank_name}` : item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button
            className="reset"
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddEditBeneficiary;
