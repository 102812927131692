import React from 'react';

// import { toast } from 'react-toastify';
import { Input, Button, Select } from 'antd';
import InputMask from 'react-input-mask';
import Form from '@component/Form';
import { useState } from 'react';
import { API, fileUpload, get } from '../../../config';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
const AddEditBeneficiaries = ({ getBeneficiaryData, setShow, clientId, beneficiaryId, record }) => {
  const [form] = Form.useForm();
  const [bankOptions, setBankOptions] = useState([{ label: 'Please Select Bank', value: '' }]);
  const [bankSearch, setBankSearch] = useState('');

  const onClose = () => {
    setShow(false);
  };

  const handleBankSearch = (value) => {
    setBankSearch(value);
  };

  const getBankList = async (searchValue) => {
    try {
      const { data } = await get(`${API.BANK_ABBREVIATION}?search=${searchValue}`);
      setBankOptions([{ label: 'Please Select Bank', value: '' }, ...data.data]);
    } catch (error) {
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(async () => {
    getBankList(bankSearch);
  }, [bankSearch]);

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      const url = beneficiaryId ? `${API.BENEFICIARY}/${beneficiaryId}` : API.BENEFICIARY;
      const reqObj = beneficiaryId
        ? { ...values, customer_id: clientId ? clientId : record?.customer_id, _method: 'PUT' }
        : { ...values, customer_id: clientId ? clientId : record?.customer_id };
      Object.keys(reqObj).forEach((key) => {
        if (reqObj[key]) {
          formData.append([key], reqObj[key]);
        }
      });
      const { data } = await fileUpload(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success(data?.message);
      getBeneficiaryData(clientId ? clientId : '');
      onClose();
    } catch (e) {
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <div className="content-details">
      <Form
        form={form}
        initialValues={{
          account_name: record?.account_name ? record?.account_name : '',
          bsb: record?.bsb ? record?.bsb : '',
          account_number: record?.account_number ? record?.account_number : '',
          bank_name: record?.bank_name ? record?.bank_name : ''
        }}
        name="beneficiariesForm"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="input-boxes">
          <Form.Item
            className="half-width"
            label="Beneficiary Name"
            name="account_name"
            rules={[
              {
                required: true,
                message: 'Please Enter Beneficiary Name'
              },
              {
                max: 200,
                message: 'Must be less than 200 character'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&-]*$/),
                message: `Beneficiary name can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-) & spaces.`
              }
            ]}
          >
            <Input placeholder="Beneficiary Name" name="account_name" />
          </Form.Item>

          <Form.Item
            className="half-width"
            label="BSB"
            name="bsb"
            rules={[
              {
                required: true,
                message: 'Please Enter BSB Number'
              }
            ]}
          >
            <InputMask mask="999-999" name="bsb" placeholder="123-456" className="ant-input" />
          </Form.Item>
          <Form.Item
            className="half-width"
            label="Account Number"
            name="account_number"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please Enter Account Number'
              },
              {
                pattern: new RegExp(/^\d{0,9}$/),
                message: 'Must be less than 10 digit number'
              },
              {
                validator: (_, value) => {
                  if (
                    (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                    (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                    (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                    (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                    (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                    (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                    (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                    (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                  ) {
                    return Promise.reject(
                      'Please enter valid Account Number. All digits of same number are not allowed.'
                    );
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            <Input placeholder="xxxxxxxx1234" />
          </Form.Item>
          <Form.Item
            label="Bank Name"
            name="bank_name"
            // validateTrigger={['onBlur']}
            className="half-width"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please select Bank Name'
            //   }
            // ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onSearch={handleBankSearch}
              placeholder={'Please select Bank'}
              allowClear
            >
              {bankOptions.map((item, i) => (
                <Select.Option
                  key={i}
                  value={item.abbreviation ? item.abbreviation : ''}
                >
                  {item.abbreviation ? `${item.abbreviation + " - " + item.bank_name}` : item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button
            className="reset"
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddEditBeneficiaries;
