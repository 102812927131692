/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { newTrialBalanceTableColumns } from './reportHelper';

import '../../App.css';
import '../../assets/css/style.css';
import {
  Table
  // Switch
} from 'antd';
import { API, get } from '../../config';

const TrialBalanceTable = ({ dateFilter, endMonth, hide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientsReportData, setClientsReportData] = useState([]);
  // const [showdata, setShowdata] = useState(true);
  const getClientsReportData = useCallback(
    async (type) => {
      try {
        setIsLoading(true);
        const { data } = await get(
          `${API.CA_TRUST_TRIAL_BALANCE_REPORT}?page=1&limit=-1&end_month=${
            dateFilter?.end_date ? dateFilter?.end_date : ''
          }&export=0&display_zero=${type}`
        );
        let totalDebit = 0;
        let totalCredit = 0;
        let firData = {
          bussiness_name: data.data.firm.bussiness_name,
          debit_amount:
            Number(data.data.firm.closing_balance) > 0 ? String(data.data.firm.closing_balance) : 0,
          credit_amount:
            Number(data.data.firm.closing_balance) < 0
              ? String(data.data.firm.closing_balance * -1)
              : 0,
          type: 'firm'
        };
        if (Number(data.data.firm.closing_balance) > 0) {
          firData.debit_amount = String(data.data.firm.closing_balance);
          totalDebit += Number(data.data.firm.closing_balance);
        } else if (Number(data.data.firm.closing_balance) < 0) {
          firData.credit_amount = String(data.data.firm.closing_balance * -1);
          totalCredit += Number(data.data.firm.closing_balance * -1);
        }
        let customerData = [];
        if (data.data.customer && data.data.customer?.length > 0) {
          customerData = data.data.customer?.map((item) => {
            // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
            let obj = {
              ...item,
              debit_amount: 0,
              credit_amount: 0
            };
            if (Number(item?.closing_balance) < 0) {
              obj.debit_amount = String(item?.closing_balance * -1);
              totalDebit += Number(item?.closing_balance * -1);
            } else if (Number(item?.closing_balance) > 0) {
              obj.credit_amount = String(item?.closing_balance);
              totalCredit += Number(item?.closing_balance);
            }
            return obj;
          });
        }
        let supplierData = [];
        if (data.data.suppliers && data.data.suppliers?.length > 0) {
          supplierData = data.data.suppliers?.map((item) => {
            // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
            let obj = {
              ...item,
              debit_amount: 0,
              credit_amount: 0
            };
            if (Number(item?.closing_balance) < 0) {
              obj.debit_amount = String(item?.closing_balance * -1);
              totalDebit += Number(item?.closing_balance * -1);
            } else if (Number(item?.closing_balance) > 0) {
              obj.credit_amount = String(item?.closing_balance);
              totalCredit += Number(item?.closing_balance);
            }
            return obj;
          });
        }
        let footerData = {
          bussiness_name: 'Total',
          debit_amount: String(totalDebit),
          credit_amount: String(totalCredit)
        };

        // let customerData = [];
        // data?.data?.customer &&
        //   data?.data?.customer?.map((item) => {
        //     if (showdata) {
        //       customerData.push(item);
        //     } else {
        //       if (item?.closing_balance != 0) {
        //         customerData.push(item);
        //       }
        //     }
        //   });
        // setClientsReportData([...[firData].concat(customerData), footerData]);
        let finalData = [];

        customerData?.length > 0
          ? (finalData = [...[firData].concat(customerData), footerData])
          : (finalData = []);

        customerData?.length > 0 && supplierData?.length > 0
          ? (finalData = [...[firData].concat(customerData).concat(supplierData), footerData])
          : supplierData?.length > 0 &&
            (finalData = [...[firData].concat(supplierData), footerData]);

        setClientsReportData(finalData);
        setIsLoading(false);
      } catch (e) {
        setClientsReportData([]);
        setIsLoading(false);
      }
    },
    [dateFilter]
  );

  // const onChange = (checked) => {
  //   setShowdata(checked);
  // };

  // useEffect(() => {
  //   getClientsReportData(1);
  // }, [getClientsReportData]);

  useEffect(() => {
    if (hide === true) {
      getClientsReportData(0);
    } else {
      getClientsReportData(1);
    }
  }, [hide, getClientsReportData]);

  return (
    <div className="table-wrapper trial-balance-table">
      <h5 className="trial-balance-heading">
        Trust Account Ledger Trial Balance
        <br />
        for
        <br />
        Firm Trust Account
      </h5>
      <h6 className="trial-balance-heading">
        For Period ended {/* {`${moment(dateFilter?.end_date, 'MM-YYYY').format('DD/MM/YYYY')}`} */}
        {endMonth}
      </h6>
      {/* <h6 className="trial-balance-heading ml-5">
        Display 0 Balance Data &nbsp;
        <Switch defaultChecked onChange={onChange} />
      </h6> */}
      <Table
        rowKey={(record) => record.id}
        columns={newTrialBalanceTableColumns}
        loading={isLoading}
        dataSource={clientsReportData || []}
        pagination={false}
      />
    </div>
  );
};

export default TrialBalanceTable;
