/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
import { Button, Col, Container, Form, Row, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import TaxSearch from '../tax/TaxSearch';
import AuthorityLetterModal from '../customer/AuthorityLetterModal';
import CurrencyInput from 'react-currency-input-field';
import { API, get } from '../../config';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import EditAtoRefund from '../tax/EditAtoRefund';
import { formatNumber } from '../../APIConfig/Interceptor';
import { Spin } from 'antd';

const ProcessRefundCard = ({
  item,
  showSearchIndexs,
  setShow,
  setShowTax,
  // setShowSearchIndexs,
  setAccountId,
  customerAccountDetails,
  setTaxSoftInfo,
  total,
  //sendReview,
  handleSearchClose,
  showCommentbox,
  unMatchData,
  processRefund,
  matchData,
  handleDescription,
  handleNote,
  handleNoteErrors,
  handleDescriptionErrors,
  descriptionErrors,
  noteErrors,
  setCustomerDetails,
  otherDetails,
  index,
  getProcessData,
  id,
  handlePrice,
  updatePrice,
  handleInvoiceNumber,
  updateInvoiceNumber,
  crn,
  hasBankDetail
}) => {
  const [reconcileid, setReconcileid] = useState([]);
  const [showView, setShowView] = useState(false);

  const [firmData, setFirmData] = useState();

  const getFirmData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_FIRM_INFO);
      const firmObj = data.data;
      setFirmData(firmObj);
    } catch (e) {
      setFirmData({});
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, []);

  useEffect(() => {
    getFirmData();
  }, [getFirmData]);

  const printauthority = (id) => {
    setReconcileid(id);
    setShowView(!showView);
  };
  const togglePrintModal = () => {
    setShowView(!showView);
  };

  const getClassName = useCallback((item) => {
    if (item?.is_matched == 1 && item?.remittance >= 0) return 'refund-process-box matched';
    if (item?.is_matched == 1 && item.remittance <= 0) return 'refund-process-box red';
    return 'refund-process-box';
  }, []);

  const [showEdit, setShowEdit] = useState(false);
  const [editAtoData, setEditAtoData] = useState(false);

  const handleCloseEdit = () => {
    setShowEdit(!showEdit);
  };

  const handleEditAto = (item) => {
    setEditAtoData(item);
    setShowEdit(!showEdit);
  };

  return (
    <div
      className={getClassName(item)}
      key={index}
      style={{
        position: 'relative'
      }}
    >
      {item?.loading && (
        <div
          style={{
            position: 'absolute',
            zIndex: 3,
            background: '#dbdbdb40',
            height: '100%',
            width: '100%',
            margin: '-15px',
            borderRadius: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Spin />
        </div>
      )}
      <Container>
        <Row className="refund-top-details">
          <Col xs={6} md={2} className="refund-checkbox"></Col>
          <Col xs={6} md={2}>
            <strong>Account Name</strong> {customerAccountDetails?.account_name}
          </Col>
          <Col xs={6} md={2}>
            <strong>CRN</strong>{' '}
            {crn ? crn : customerAccountDetails?.crn ? customerAccountDetails?.crn : '-'}
          </Col>
          <Col xs={6} md={2}>
            <strong>BSB</strong>
            {/* {customerAccountDetails?.bsb
              ? 'xxx' + customerAccountDetails?.bsb?.toString().slice(-4)
              : ''} */}
            {customerAccountDetails?.bsb ? customerAccountDetails?.bsb?.toString() : ''}
          </Col>
          <Col xs={6} md={2}>
            <strong>Account Number</strong>{' '}
            {/* {customerAccountDetails?.account_number
              ? 'xxxx xxxx ' + customerAccountDetails?.account_number?.toString().slice(-4)
              : ''} */}
            {customerAccountDetails?.account_number
              ? customerAccountDetails?.account_number?.toString()
              : ''}
          </Col>
          {item?.is_matched == 1 ? (
            <Col xs={6} md={2} className="price-box">
              <strong>Net Remittance</strong>{' '}
              {`${formatNumber((item?.ato?.refund || 0) - total) || 0}`}
            </Col>
          ) : null}
          {item?.is_matched == 1 ? (
            <Col xs={6} md={2}>
              <strong>Description on Statement</strong>{' '}
              <Form.Control
                type="text"
                id={item.id}
                required
                minLength={4}
                maxLength={18}
                value={item.description ? item.description : ''}
                onChange={handleDescription}
                rules={[
                  {
                    pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                    message:
                      'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                  }
                ]}
                onBlur={() => handleDescriptionErrors(item.id, item?.invoice_number)}
                placeholder={item.description ? item.description : ''}
              ></Form.Control>
              {Object.prototype.hasOwnProperty.call(descriptionErrors, item.id) && (
                <p className="text-danger">{`${descriptionErrors[item.id]}`}</p>
              )}
            </Col>
          ) : null}
        </Row>
        <Container>
          <Row className="refund-card-box">
            <Col xs={6} md={5}>
              <div className="refund-card">
                {item && (
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <FontAwesomeIcon icon={faEllipsisV} size="1x" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {item?.status !== '2' && (
                        <Dropdown.Item
                          onClick={() => {
                            showCommentbox(item.id, item);
                          }}
                        >
                          Send For Review
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        onClick={() => {
                          handleEditAto(item);
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <label>ATO Refund </label>
                {item && item.ato && Object.keys(item.ato).length > 0 ? (
                  <div className="refund-card-content">
                    <div className="left-block">
                      <p className="name">
                        <strong>Name:</strong> {item?.ato?.name}
                      </p>
                      {/* <p>
                        <strong>TFN:</strong> {item?.ato?.tfn}
                      </p> */}
                      <p>
                        <strong>Year:</strong> {item?.ato?.year ? item?.ato?.year : ''}
                      </p>
                    </div>
                    <div className="right-block">
                      <p className="rightalign">
                        <strong>ATO Refund Amount:</strong>
                        {`$${formatNumber(item?.ato?.refund) || 0}`}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="refund-card-content">
                    <span>
                      No ATO Refund entry available for {customerAccountDetails?.account_name}{' '}
                      <a
                        style={{ color: '#0077C7', fontSize: '14px' }}
                        onClick={() => setShow(true)}
                      >
                        Click here
                      </a>{' '}
                      to Create new ATO Refund entery manually
                    </span>
                  </div>
                )}
              </div>
              {item.comment && (
                <Row className="refund-top-details">
                  <Col>
                    <label>Review Comment</label>{' '}
                    <Form.Control
                      type="text"
                      id={item.id}
                      value={item.comment ? item.comment : ''}
                      // onChange={handleCommentValue}
                    ></Form.Control>{' '}
                  </Col>
                </Row>
              )}
              <Row className="refund-top-details">
                <Col>
                  <label>File Note</label>{' '}
                  <Form.Control
                    type="text"
                    id={item.id}
                    required
                    maxLength={150}
                    value={item?.ato?.note ? item?.ato?.note : ''}
                    onChange={handleNote}
                    rules={[
                      {
                        pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                        message:
                          'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                      }
                    ]}
                    onBlur={() => handleNoteErrors(item.id)}
                    placeholder={item?.ato?.note ? item?.ato?.note : ''}
                  ></Form.Control>
                  {Object.prototype.hasOwnProperty.call(noteErrors, item.id) && (
                    <p className="text-danger">{`${noteErrors[item.id]}`}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={6} md={2} className="matching-btn-section">
              {item?.is_matched == 1 ? (
                <Button
                  variant="secondary"
                  className="matching-btn"
                  size="sm"
                  onClick={() => {
                    unMatchData(item.id, item);
                  }}
                >
                  {' '}
                  Unmatch{' '}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  className="matching-btn"
                  size="sm"
                  disabled={item.tax_software == null || !item.ato}
                  onClick={() => {
                    matchData(item.id, item);
                  }}
                >
                  {' '}
                  Match
                </Button>
              )}
            </Col>
            <Col xs={6} md={5}>
              <div className="refund-card">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <FontAwesomeIcon icon={faEllipsisV} size="1x" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {item?.tax_software == null ? (
                      <Dropdown.Item
                        onClick={() => {
                          setAccountId(item.id);
                          setTaxSoftInfo({});
                          setShowTax(true);
                          setCustomerDetails({
                            customerId: otherDetails?.id,
                            customerFirstName: otherDetails?.first_name,
                            customerLastName: otherDetails?.last_name
                          });
                        }}
                      >
                        Create
                      </Dropdown.Item>
                    ) : null}

                    {/* <Dropdown.Item
                      onClick={() => {
                        const oldIndex = [...showSearchIndexs];
                        if (showSearchIndexs.indexOf(item.id) === -1) {
                          oldIndex.push(item.id);
                        } else {
                          oldIndex.splice(showSearchIndexs.indexOf(item.id), 1);
                        }
                        setShowSearchIndexs(oldIndex);
                      }}
                    >
                      Search
                    </Dropdown.Item> */}

                    {item?.tax_software ? (
                      <Dropdown.Item
                        onClick={() => {
                          setAccountId(item.id);
                          setTaxSoftInfo({
                            ...item.tax_software,
                            customer_id: item.customer_id,
                            disbursement: item.disbursement
                          });
                          setShowTax(true);
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                    ) : null}

                    {/* <Dropdown.Item>Generate Invoice</Dropdown.Item> */}
                    {item && item?.status !== '2' && (
                      <Dropdown.Item
                        onClick={() => {
                          showCommentbox(item.id, item);
                        }}
                      >
                        Send For Review
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <label>Tax Software/Invoice Entry</label>
                {item?.tax_software ? (
                  <div className="refund-card-content">
                    <div className="left-block">
                      <p className="name">
                        <strong>Name:</strong> {item?.tax_software?.first_name}{' '}
                        {item?.tax_software?.last_name}
                      </p>
                      {/* <p>
                        <strong>TFN:</strong> {item?.tax_software?.tfn}
                      </p> */}
                      <p>
                        <strong>Year:</strong> {item?.tax_software?.year}
                      </p>
                    </div>
                    <div className="right-block">
                      <p className="rightalign">
                        <strong>
                          Refund as per{' '}
                          {item?.tax_software?.tax_software
                            ? item?.tax_software?.tax_software
                            : firmData?.tax_software}
                          :
                        </strong>{' '}
                        {`$${formatNumber(item?.tax_software?.refund) || 0}`}
                      </p>
                      {item?.is_matched == 1 ? (
                        <p className="rightalign">
                          <strong>Fee:</strong>
                          <CurrencyInput
                            prefix="$"
                            style={{
                              width: '40%',
                              'border-radius': '15px',
                              'text-align': 'center',
                              borderWidth: '1px',
                              borderColor:
                                parseFloat(item?.tax_software?.invoice_amount) >= 0
                                  ? 'black'
                                  : 'red'
                            }}
                            id="input-example"
                            name="input-name"
                            defaultValue={
                              item?.tax_software?.invoice_amount &&
                              item?.tax_software?.invoice_amount > 0
                                ? item?.tax_software?.invoice_amount
                                : item?.tax_software?.invoice_amount
                            }
                            value={item?.tax_software?.invoice_amount}
                            decimalsLimit={2}
                            onValueChange={(value) => handlePrice(item.id, value)}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                updatePrice(item?.tax_software?.invoice_amount, item.id);
                              }
                            }}
                            onBlur={() => updatePrice(item?.tax_software?.invoice_amount, item.id)}
                          />
                          {/* {item?.tax_software?.invoice_amount &&
                          parseFloat(item?.tax_software?.invoice_amount) > 0 ? (
                            <span>{`$${item?.tax_software?.invoice_amount?.toFixed(2) || 0}`}</span>
                          ) : (
                            <span style={{ color: 'red' }}>
                              {`$${item?.tax_software?.invoice_amount?.toFixed(2) || 0}`}
                            </span>
                          )} */}
                          {/* {`$${item?.tax_software?.invoice_amount?.toFixed(2) || 0}`} */}
                        </p>
                      ) : null}

                      {item?.is_matched == 1 ? (
                        <p className="rightalign">
                          <strong>Disbursement:</strong>
                          {`$${formatNumber(item?.tax_software?.disbursement) || 0}`}
                        </p>
                      ) : null}

                      {item?.is_matched == 1 ? (
                        <p className="rightalign">
                          <b>
                            Total Fee:
                            <span>{`$${formatNumber(total) || 0}`}</span>
                          </b>
                        </p>
                      ) : null}
                    </div>
                    {item?.is_matched == 1 ? (
                      <p className="rightalign invoice_number_input">
                        <strong>Invoice No:</strong>
                        <Form.Control
                          type="text"
                          id={item.id}
                          required
                          value={item?.invoice_number ? item?.invoice_number : ''}
                          onChange={(e) => handleInvoiceNumber(item.id, e?.target?.value)}
                          onBlur={() =>
                            updateInvoiceNumber(item?.invoice_number, item.id, item?.description)
                          }
                          placeholder={'Enter Invoice No.'}
                        ></Form.Control>
                      </p>
                    ) : null}

                    {item?.is_matched == 1 ? (
                      <p className="rightalign remittance">
                        <strong>Remittance:</strong>
                        {`$${formatNumber((item?.ato?.refund || 0) - total) || 0}`}
                        {/* {`$${item?.remittance.toFixed(2) || 0}`} */}
                      </p>
                    ) : null}
                  </div>
                ) : (
                  <div className="refund-card-content">
                    <div className="left-block">
                      <p className="name">
                        <strong>Name:</strong>
                      </p>
                      {/* <p>
                        <strong>TFN:</strong>
                      </p> */}
                      <p>
                        <strong>Year:</strong>
                      </p>
                    </div>
                    <div className="right-block">
                      <p className="rightalign">
                        <strong>
                          Refund as per{' '}
                          {item?.tax_software?.tax_software
                            ? item?.tax_software?.tax_software
                            : firmData?.tax_software}
                          :
                        </strong>{' '}
                        {`$${item?.tax_software?.refund || 0}`}
                      </p>
                    </div>
                  </div>
                )}
                {item?.is_matched == 1 ? (
                  <div className="table-btn">
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        printauthority(item.id);
                      }}
                    >
                      Print Authority Letter
                    </Button>
                    {item?.remittance < 0 ? (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          showCommentbox(item.id);
                        }}
                      >
                        Send For Review
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          processRefund(item.id, index);
                        }}
                        disabled={!hasBankDetail}
                      >
                        Process Refund
                      </Button>
                    )}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>

          {showSearchIndexs.indexOf(item.id) !== -1 ? (
            <TaxSearch ItemId={item.id} close={handleSearchClose} />
          ) : null}
        </Container>
      </Container>
      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Edit ATO Refund</Modal.Header>
        <Modal.Body>
          <EditAtoRefund
            id={id}
            getProcessData={getProcessData}
            editAtoData={editAtoData}
            onClose={handleCloseEdit}
          />
          <Button variant="secondary" className="reset" onClick={handleCloseEdit}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
      {showView && (
        <AuthorityLetterModal
          id={reconcileid}
          togglePrintModal={togglePrintModal}
          screenView="processRefund"
        />
      )}
    </div>
  );
};

export default ProcessRefundCard;
