/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Input, Select, Spin, Table } from 'antd';
// import { debounce } from 'lodash';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import useSupplierList from './useSupplierList';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import AddEditSupplier from './AddEditSupplier.js';
import { API, defaultPaginationLimit, deleteCall, fileUpload } from '../../../config';
import '../../../App.css';
import '../../../assets/css/style.css';
import '../Task/tasksetting.module.css';
import SupplierDetailPopup from './SupplierDetailPopup';
import { capitalizeFirstLetter, trimString } from '../../../helper/fullNameGetter';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserSelectWithNewUser from '../../../components/User/UserSelectWithNewUser.js';

const SupplierList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [supplier, setSupplier] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const [newClientModal, setNewClientModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [form] = Form.useForm();

  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const client_id = query.get('client_id') ? parseInt(query.get('client_id')) : null;
  const [clientId, setClientId] = useState(client_id ? client_id : '');
  const {
    fetchSuppliers,
    suppliers,
    isDataLoading,
    pagingData,
    onPageChange
    // onSearchChange,
    // searchParam
  } = useSupplierList(currentPage, search, limit);
  const handleClose = () => {
    setShow(false);
    setSupplier(null);
    fetchSuppliers(limit, clientId);
  };
  const toggleShowModal = () => {
    setShowView(!showView);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
    setSupplier(null);
  };

  const supplierTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      className: 'centeralign',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (record) => trimString(capitalizeFirstLetter(record), 32),
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Business Name',
      dataIndex: 'business_name',
      render: (record) => capitalizeFirstLetter(record),
      sorter: (a, b) => a.business_name.localeCompare(b.business_name)
    },
    {
      title: 'Client Name',
      // dataIndex: '',
      render: (record) => {
        return record?.customer?.first_name
          ? record?.customer?.first_name +
              ' ' +
              record?.customer?.last_name +
              (record?.customer?.crn ? ' (' + record?.customer?.crn + ')' : '')
          : record?.customer?.business_name
          ? record?.customer?.business_name +
            (record?.customer?.crn ? ' (' + record?.customer?.crn + ')' : '')
          : record?.customer?.last_name
          ? record?.customer?.last_name +
            (record?.customer?.crn ? ' (' + record?.customer?.crn + ')' : '')
          : '';
      }
    },
    {
      title: 'Action',
      width: '10%',
      dataIndex: 'id',
      className: 'centeralign',
      render: (id, record) => {
        return (
          <>
            <FontAwesomeIcon
              onClick={() => {
                setSupplier(record);
                setShowView(true);
              }}
              icon={faEye}
              size="1x"
            />
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={() => {
                setSupplier(record);
                setShow(true);
              }}
            />
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              icon={faTrash}
              size="1x"
              onClick={() => {
                setSupplier(record);
                setShowDelete(true);
              }}
            />
          </>
        );
      }
    }
  ];

  const deleteSupplier = () => {
    try {
      deleteCall(API.SUPPLIERS + `/${supplier.id}`).then((response) => {
        handleDeleteClose();
        fetchSuppliers(limit, clientId);
        toast.success(response.data.message);
      });
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/settings?tab=supplier&page=1&limit=${value}`, {
      replace: true
    });
    fetchSuppliers(value, clientId);
  };

  const onSelect = (value) => {
    setClientId(value);
    fetchSuppliers(limit, value);
    navigate(`/settings?tab=supplier&client_id=${value}`);
  };

  const handleAddNewClient = () => {
    setNewClientModal(!newClientModal);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const url = API.CREATE_CUSTOMER;
      const reqObj = values;
      Object.keys(reqObj).forEach((key) => {
        if (reqObj[key]) {
          formData.append([key], reqObj[key]);
        }
      });
      const { data } = await fileUpload(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success(data.message);
      if (data?.status === 200) {
        handleAddNewClient();
        onSelect(data?.data?.customer?.id);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleNewBeneficiary = () => {
    setShow(true);
  };

  return (
    <div className="cms-page suppliers-page ">
      <div className="content-details">
        <div className="table-top-btn suppliers-table-top">
          <div className="search-section process-search">
            {!loading && (
              <UserSelectWithNewUser
                customerId={Number(clientId)}
                formStyle={{ width: 252 }}
                onCustomerSelectionChange={onSelect}
                handleAddNewClient={handleAddNewClient}
                onClear={() => {
                  setClientId();
                  navigate(`/settings?tab=supplier`);
                  fetchSuppliers(limit, '');
                }}
              />
            )}

            <span className="suppliers-or-text">or</span>
            <Link
              to={{ pathname: '/new-customers' }}
              className="action solid-btn suppliers-add-client"
            >
              Add Client
            </Link>
          </div>
          {/* <div className="search-section">
            <Form>
              <FormControl
                onChange={(e) => debounce(onSearchChange(e), 300)}
                type="text"
                value={searchParam}
                placeholder="Search Supplier"
              />
              <Button className="fa fa-search">Search</Button>
            </Form>
          </div> */}
          <div className="table-btn">
            <Button variant="primary" onClick={() => handleNewBeneficiary()}>
              Add New
            </Button>
          </div>
        </div>
        <Table
          className="task-table"
          columns={supplierTableColumns}
          dataSource={suppliers}
          loading={isDataLoading}
          pagination={{
            hideOnSinglePage: true,
            defaultCurrent: 1,
            showQuickJumper: true,
            defaultPageSize: pagingData.pageSize,
            onChange: (page) => onPageChange(page, limit),
            itemRender: PaginationItemRenderer,
            ...pagingData,
            pageSize: limit
          }}
        />
        {suppliers?.length > 0 && (
          <div className="mt-2">
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={limit}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          </div>
        )}
      </div>
      <Modal
        className="mid-modal supplier-modal"
        id="supplier_modal"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>{supplier ? 'Edit' : 'Add'} Supplier</Modal.Header>
        <Modal.Body>
          <AddEditSupplier supplier={supplier} onClose={handleClose} clientID={clientId} />
        </Modal.Body>
      </Modal>

      <Modal
        className="small-modal remove-popup"
        show={showDelete}
        onHide={handleDeleteClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={handleDeleteClose}></Modal.Header>
        <Modal.Body>
          <div className="remove-content">
            <p>Are you sure you want to delete this Supplier?</p>
            <div className="modal-action">
              <Button
                className="cancel"
                onClick={() => {
                  setShowDelete(false);
                  setSupplier(null);
                }}
              >
                Cancel
              </Button>
              <Button onClick={deleteSupplier}>OK</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showView && (
        <SupplierDetailPopup supplierId={supplier.id} toggleShowModal={toggleShowModal} />
      )}

      <Modal
        className="small-modal"
        show={newClientModal}
        onHide={handleAddNewClient}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Add New Contact</Modal.Header>
        <Modal.Body>
          <div className="content-details">
            {loading && <Spin />}
            <Form
              // form={form}
              name="officeForm"
              initialValues={{ first_name: '', last_name: '', tfn: '', email: '' }}
              layout="vertical"
              onFinish={handleSubmit}
            >
              <div className="input-boxes">
                <>
                  <Form.Item
                    className="full-width"
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        max: 32,
                        message: 'Must be less than 32 character'
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: 'First name can contain letters & spaces.'
                      }
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Last Name Or Trading Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Last name'
                      },
                      {
                        max: 150,
                        message: 'Must be less than 150 character'
                      },
                      {
                        pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&]*$/),
                        message:
                          'Last name can contain letters, numbers, ampersand(&), dot(.), comma(,) & spaces.'
                      }
                    ]}
                  >
                    <Input /*onChange={handleLastName}*/ placeholder="Last Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="TFN"
                    name="tfn"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter TFN'
                      },
                      {
                        pattern: new RegExp(/^([0-9]+$)([ .-]?)/),
                        message: 'Please enter valid TFN'
                      },
                      {
                        min: 8,
                        message: 'Minimum 8 digits are required in TFN'
                      },
                      {
                        max: 9,
                        message: 'Maximum 9 digits are allowed in TFN'
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                            return Promise.reject(
                              'Please enter valid TFN Number. All digits of same number are not allowed.'
                            );
                          } else {
                            if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                              return Promise.reject(
                                'Please enter valid TFN Number. All digits of same number are not allowed.'
                              );
                            }
                            return Promise.resolve();
                          }
                        }
                      }
                    ]}
                  >
                    <Input placeholder="123412345" value="" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Email'
                      },
                      {
                        type: 'email',
                        message: 'Please enter valid Email'
                      }
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </>
              </div>
              <div className="action-box">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </div>
          <Button
            variant="secondary"
            className="reset suppliers-cancel-btn"
            onClick={handleAddNewClient}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SupplierList;
